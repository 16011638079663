import { Link } from "gatsby";
import React from "react";
import ArrowIcon from "assets/breadcrumbBlueArrow.png";

const Breadcrumb = ({ path }) => {
  return (
    <div className="flex gap-3.5 items-center">
      <Link to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.7926 8.25827L8.59343 0.253355C8.29026 -0.0844518 7.70793 -0.0844518 7.40476 0.253355L0.205564 8.25827C0.101941 8.37321 0.033923 8.51581 0.00978135 8.66872C-0.0143603 8.82164 0.00641428 8.97828 0.0695792 9.11959C0.197565 9.40857 0.483933 9.59428 0.799898 9.59428H2.39972V15.1977C2.39972 15.41 2.48399 15.6136 2.63401 15.7638C2.78402 15.9139 2.98748 15.9982 3.19963 15.9982H5.59936C5.81151 15.9982 6.01497 15.9139 6.16498 15.7638C6.315 15.6136 6.39927 15.41 6.39927 15.1977V11.9958H9.59891V15.1977C9.59891 15.41 9.68319 15.6136 9.8332 15.7638C9.98322 15.9139 10.1867 15.9982 10.3988 15.9982H12.7986C13.0107 15.9982 13.2142 15.9139 13.3642 15.7638C13.5142 15.6136 13.5985 15.41 13.5985 15.1977V9.59428H15.1983C15.3532 9.59494 15.505 9.55048 15.6351 9.46632C15.7652 9.38216 15.868 9.26194 15.931 9.12031C15.994 8.97868 16.0145 8.82176 15.9899 8.6687C15.9653 8.51563 15.8968 8.37302 15.7926 8.25827Z"
            fill="#00173A"
          />
        </svg>
      </Link>
      {path.map((elem, i) => (
        <>
          <img src={ArrowIcon} />
          {i === 0 ? (
            <Link to="/legal" className="text-[#7F7F7F]">
              {elem}
            </Link>
          ) : (
            <span className={"text-black  font-opensans text-[15px] "}>
              {elem}
            </span>
          )}
        </>
      ))}
    </div>
  );
};

export default Breadcrumb;
