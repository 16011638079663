import Layout from "components/layout";
import React from "react";
import Breadcrumb from "components/LegalPolicies/Breadcrumb";
import SEO from "components/seo";
import { Link } from "gatsby";
import backToTopIcon from "assets/backToTop.svg";

function Legal() {
  const data = [
    {
      title: "Terms of Service",
      description:
        "Our Terms of Service establish the rules and responsibilities for using our website and services. By accessing our site, you agree to abide by these terms.",
      url: "/legal/terms-of-service",
    },
    {
      title: "Privacy Policy",
      description:
        "Your privacy matters to us. Our Privacy Policy explains how we collect, use, and protect your personal information. Please read it to understand how we handle your data.",
      url: "/legal/privacy-policy",
    },
    {
      title: "Cookie Policy",
      description:
        "We use cookies to enhance your browsing experience. Our Cookie Policy provides detailed information about the types of cookies we use and why.",
      url: "/legal/cookie-policy",
    },
    {
      title: "Information Security Page",
      description:
        "Protecting your data is our priority. Our Information Security Policy outlines the security measures in place, including encryption, data storage, and security protocols.",
      url: "/legal/information-security",
    },
    {
      title: "Legal Disclaimer",
      description:
        "Our Legal Disclaimer clarifies that the information presented on our website is for informational purposes only and does not constitute legal advice.",
      url: "/legal/disclaimer",
    },
    {
      title: "Accessibility Statement",
      description:
        "Tomedes is committed to ensuring that our website is accessible to all individuals, including those with disabilities. Please read our Accessibility Statement to learn more about our efforts in this regard.",
      url: "/legal/accessibility",
    },
  ];

  const backtoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <SEO
        title="Tomedes' Legal Policies"
        description="Review Tomedes' Legal Policies for clarity on user rights, privacy, and cookies. Stay informed for a smooth website experience."
        keywords=""
        slug="/legal"
      />
      <Layout>
        <div className="max-w-7xl p-4 mx-auto py-12">
          <Breadcrumb path={["Legal Policies"]} />
          <h1 className="text-5xl font-bold mt-[50px]">
            Tomedes Legal Policies
          </h1>
          <div className="mt-6">
            <p className="font-opensans  text-lg ">
              We're committed to ensuring transparency and safeguarding your
              rights while using our website and services.
            </p>
            <p className="font-opensans text-lg mt-5">
              Your use of our website constitutes your acceptance of these
              policies.
            </p>
          </div>
          <div className="grid lg:grid-cols-2 gap-8  mt-[50px]">
            {data.map((item, i) => (
              <Link
                to={item.url}
                key={i}
                className=" p-6 rounded-lg space-y-4 border  text-[#131313] hover:text-white border-[#E3E3E3] hover:bg-[#011C2A]"
                style={{ boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)" }}
              >
                <h1 className="text-3xl  font-opensans font-bold">
                  {item.title}
                </h1>
                <p className="text-lg">{item.description}</p>
              </Link>
            ))}
          </div>
          <div className=" font-opensans text-lg py-[50px]">
            <p className="">
              Please note that our policies may be updated periodically to
              reflect changes in our practices or legal requirements. Users will
              be informed of any significant policy changes.
            </p>
            <p className="mt-5">
              For any legal inquiries or questions regarding our policies,
              please don't hesitate to reach out to us at{" "}
              <a href="mailto:support@tomedes.com" className="text-[#005CB1]">
                support@tomedes.com{" "}
              </a>{" "}
              or through our{" "}
              <Link to="/contactus.php" className="text-[#005CB1]">
                contact form.
              </Link>{" "}
              Your concerns are important to us, and we are here to assist you.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={backToTopIcon}
              alt="backIcon"
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <span className="mt-4 font-primary">Back To Top</span>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Legal;
